const { config } = require('./../commerce/config')
const { defaultLocale, fakeLocale } = config

const processLocale = (locale: string | undefined) => {
  let processLocale = locale || defaultLocale
  if (processLocale === fakeLocale) processLocale = defaultLocale

  return processLocale
}

export default processLocale

export type PaymentMethods = 'AFTERPAY' | 'CLEARPAY' | 'KLARNA'

interface Currency {
  name: string
  value: string
}
interface LOCALE_DATA {
  name: string
  code: string
  countrySelectorCode: string
  value: string
  currency?: Currency[]
}
export const LOCALES_MAP: Record<string, LOCALE_DATA> = {
  'en-nz': {
    name: 'New Zealand',
    code: 'NZ',
    countrySelectorCode: 'NZ',
    value: 'en-nz',
    currency: [
      {
        name: '$ NZD',
        value: 'NZ',
      },
    ],
  },
  'en-au': {
    name: 'Australia',
    code: 'AU',
    countrySelectorCode: 'AUS',
    value: 'en-au',
    currency: [
      {
        name: '$ AUD',
        value: 'AU',
      },
    ],
  },
  'en-us': {
    name: 'United States',
    code: 'US',
    countrySelectorCode: 'USA',
    value: 'en-us',
    currency: [
      {
        name: '$ USD',
        value: 'US',
      },
    ],
  },
  'en-gb': {
    name: 'United Kingdom',
    code: 'UK',
    countrySelectorCode: 'UK',
    value: 'en-gb',
    currency: [
      {
        name: '£ GBP',
        value: 'UK',
      },
    ],
  },
  'en-ca': {
    name: 'Canada',
    code: 'CA',
    countrySelectorCode: 'CAN',
    value: 'en-ca',
    currency: [
      {
        name: '$ CAD',
        value: 'CA',
      },
    ],
  },
  en: {
    name: 'International',
    code: 'ROW',
    countrySelectorCode: 'INT',
    value: 'en',
    currency: [
      {
        name: '$ AUD',
        value: 'AU',
      },
    ],
  },
}

interface FOURSIXTY_LOCALES_MAP {
  dataDomain: string
  dataFeedId: string
}

export const FOURSIXTY_LOCALES_MAP: Record<string, FOURSIXTY_LOCALES_MAP> = {
  'en-nz': {
    dataDomain: '',
    dataFeedId: 'the-sheet-society',
  },
  'en-au': {
    dataDomain: '',
    dataFeedId: 'the-sheet-society',
  },
  'en-us': {
    dataDomain: 'thesheetsociety.myshopify.com',
    dataFeedId: 'the-sheet-society_1',
  },
  // 'en-uk': {
  //   dataDomain: '',
  //   dataFeedId: 'the-sheet-society',
  // },
  'en-ca': {
    dataDomain: '',
    dataFeedId: 'the-sheet-society',
  },
  en: {
    dataDomain: '',
    dataFeedId: 'the-sheet-society',
  },
}

export const getConfigByLocale = (locale: string | undefined, configName: string) => {
  const { context } = config
  const currentLocale = processLocale(locale)
  const currentContext = context?.[currentLocale]

  return currentContext?.[configName]
}

export const getFullCountryName = (locale: string) => LOCALES_MAP?.[locale]?.name || ''

export const getCurrenceCodeByLocale = (locale?: string) => {
  const l = processLocale(locale)
  const currencyName = LOCALES_MAP?.[l]?.currency?.[0].name
  if (l === 'en-gb' && currencyName) {
    return currencyName.replace('£ ', '') // Replace '£ ' with an empty string
  }

  return currencyName?.replace('$ ', '') || ''
}

export const getLocaleByCountryCode = (countryCode: string) => {
  const locale = Object.keys(LOCALES_MAP).find((key) => LOCALES_MAP[key].code === countryCode)
  return locale || defaultLocale
}
