import Script from 'next/script'
import NextHead from 'next/head'
import { FC } from 'react'
const GoogleOptimizeAntiFlicker: FC<{ containerID: string }> = ({ containerID }) => {
  return (
    <>
      <NextHead>
        <style
          dangerouslySetInnerHTML={{
            __html: `
      .async-hide { opacity: 0 !important} 
    `,
          }}
        ></style>
      </NextHead>
      <Script
        id="google-optimize-antiflicker"
        dangerouslySetInnerHTML={{
          __html: `
      (function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
      h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
      (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
      })(window,document.documentElement,'async-hide','dataLayer',4000,
      {'${containerID}':true});
    `,
        }}
      />
    </>
  )
}

export default GoogleOptimizeAntiFlicker
