/* eslint-disable @next/next/no-sync-scripts */
import { FC } from 'react'
import NextHead from 'next/head'
import GoogleOptimizeAntiFlicker from './GoogleOptimizeAntiFlicker'

const GoogleOptimizeScript: FC<{ containerID: string | null; isAsync: boolean; antiFlicker: boolean }> = ({
  containerID,
  isAsync,
  antiFlicker,
}) => {
  if (!containerID || containerID == null) {
    return null
  }
  return (
    <>
      {isAsync ? (
        <NextHead>
          <script
            id="google-optimize"
            async
            src={`https://www.googleoptimize.com/optimize.js?id=${containerID}`}
          ></script>
        </NextHead>
      ) : (
        <NextHead>
          <script id="google-optimize" src={`https://www.googleoptimize.com/optimize.js?id=${containerID}`}></script>
        </NextHead>
      )}
      {antiFlicker && <GoogleOptimizeAntiFlicker containerID={containerID} />}
    </>
  )
}

export default GoogleOptimizeScript
