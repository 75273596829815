import { default as config } from '@config/seo.json'
import { DefaultSeo } from 'next-seo'
import NextHead from 'next/head'
import { FC } from 'react'
import GoogleOptimizeScript from '../GoogleOptimize/GoogleOptimizeScript'
import Elevar from './elevar'
import Gorgias from './gorgias'
import gtm from './gtm'
import hotjar from './hotjar'
import ImpactTag from './impactTag'

interface HeadProps {
  globalSettings?: {
    impact_tag?: boolean
    enable_newsletter_popup: boolean
    klaviyo_list_id: string
    google_optimize_container_id: string
    google_optimize_async: boolean
    google_optimize_anti_flicker: boolean
    body: {
      items: {
        [key: string]: any
      }[]
      slice_type: string
    }[]
  }
}

const Head: FC<HeadProps> = ({ globalSettings }) => {
  return (
    <>
      <DefaultSeo {...config} />
      {globalSettings?.impact_tag && globalSettings.impact_tag !== null && <ImpactTag />}
      {globalSettings?.google_optimize_container_id && globalSettings.google_optimize_container_id !== null && (
        <GoogleOptimizeScript
          containerID={globalSettings?.google_optimize_container_id || null}
          isAsync={globalSettings?.google_optimize_async || false}
          antiFlicker={globalSettings?.google_optimize_anti_flicker || false}
        />
      )}
      <NextHead>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" />
        {`google site verification for websavvy (charlene)`}
        <meta
          name="google-site-verification"
          key="websavvy-marketing"
          content="fRQkwaxOzWXP07uawNy847pS_cdI86c2zti_A5PRh9E"
        />
        <link rel="manifest" href="/site.webmanifest" key="site-manifest" />

        <link rel="preload" href="/en/fonts/basis-grotesque-bold-pro.otf" as="font" type="font/otf" crossOrigin="" />
        <link rel="preload" href="/en/fonts/basis-grotesque-medium-pro.otf" as="font" type="font/otf" crossOrigin="" />
        <link rel="preload" href="/en/fonts/basis-grotesque-regular-pro.otf" as="font" type="font/otf" crossOrigin="" />
      </NextHead>
      <Elevar />
      {Gorgias}
      {gtm}
      {hotjar}
    </>
  )
}

export default Head
