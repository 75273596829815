import { EVENT_DEBUG, infoLog } from './general'
import { CUSTOMER_SESSION_NAME } from '@components/hooks/useCustomer'

declare global {
  interface Window {
    ire: any
  }
}

export const impactTagEvent: () => void = () => {
  const customerData = window.sessionStorage.getItem(CUSTOMER_SESSION_NAME)
  let customer = {
    e: '',
    i: '',
  }
  if (customerData) {
    customer = JSON.parse(customerData)
  }
  if (EVENT_DEBUG && !window?.ire) {
    infoLog('impactTagEvent: ire function not found.')
  }

  if (window?.ire) {
    const data = {
      customerId: customer?.i ? customer.i : '',
      customerEmail: customer?.e ? customer.e : '',
    }
    window?.ire('identify', data)
  }
}
