import { ELEVAR_CUSTOMER_SESSION_NAME, ELEVAR_USER_DATA_SESSION_NAME } from '@lib/hooks/useUserProperties';
import { EcommerceData, ElevarEvent, ElevarEventType, UserProperties } from '@lib/types/Elevar';
declare global {
  interface Window {
    ElevarDataLayer: any
    ElevarInvalidateContext: any
    ElevarDebugMode: any
    ElevarTransformFn: any
    ElevarUserIdFn: any
  }
}

export const ElevarEvents = (eventType: ElevarEventType, userProperties: UserProperties, ecommerceData?: EcommerceData, cart_total?: string) => {

  let eventData: ElevarEvent = {
    event: eventType,
    user_properties: userProperties,
  };
  if (cart_total) {
    eventData.cart_total = cart_total;
  }
  if (ecommerceData) {
    eventData.ecommerce = ecommerceData;
  }
  window.ElevarDataLayer = window.ElevarDataLayer ?? [];
  if (window.ElevarDataLayer) window.ElevarDataLayer.push(eventData)
}

export const pushContextToDataLayer = () => {
  if (window?.ElevarInvalidateContext) {
    window.ElevarInvalidateContext()
  }
}

export const pushUserDataToDataLayer = () => {
  if (window?.sessionStorage) {
    const userProperties = getUserPropertiesFromSessionStorage();
    const eventData = window?.sessionStorage.getItem(ELEVAR_USER_DATA_SESSION_NAME)
    if (eventData) {
      const ecommerceData: EcommerceData = JSON.parse(eventData)?.ecommerceData
      const cartTotal = JSON.parse(eventData)?.cart_total
      ecommerceData && cartTotal && ElevarEvents('dl_user_data', userProperties, ecommerceData, cartTotal)
    }
  }

}

export const getUserPropertiesFromSessionStorage = () => {
  if (window?.sessionStorage) {
    const userProperties = window?.sessionStorage.getItem(ELEVAR_CUSTOMER_SESSION_NAME)
    if (userProperties) {
      return JSON.parse(userProperties)
    }
    return {
      user_consent: "",
      visitor_type: "guest"
    }
  }
}