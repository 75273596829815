import { useQuery, useReactiveVar } from '@apollo/client';
import { customerAccessTokenVar } from '@commerce/client';
import { getCustomerQuery } from '@commerce/utils';
import { getNumberPartFromId } from '@lib/events/general';
import { UserProperties } from '@lib/types/Elevar';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
export const ELEVAR_CUSTOMER_SESSION_NAME = 'ECSN'
export const ELEVAR_USER_DATA_SESSION_NAME = 'EUDN'
function useUserProperties() {
  //Elevar user_properties
  const { locale } = useRouter()
  const customerAccessToken = useReactiveVar(customerAccessTokenVar)
  const { data: customerData, loading: isLoadingCustomer } = useQuery(getCustomerQuery, {
    variables: { customerAccessToken },
    context: { locale },
    skip: !(customerAccessToken && customerAccessToken.length > 0),
  })
  const customer = customerData?.customer
  const [userProperties, setUserProperties] = useState<UserProperties>({
    user_consent: '',
    visitor_type: 'guest'
  });
  useEffect(() => {
    if (customer) {
      setUserProperties((prevUserProperties) => {
        const userProperties = {
          ...prevUserProperties,
          ...(customer.id && { customer_id: getNumberPartFromId(customer.id) }),
          ...(customer.email && { customer_email: customer.email }),
          ...(customer.firstName && { customer_first_name: customer.firstName }),
          ...(customer.lastName && { customer_last_name: customer.lastName }),
          ...(customer.phone && { customer_phone: customer.phone }),
          ...(customer.tags && { customer_tags: customer.tags.join(',') }),
          visitor_type: 'logged_in'
        }
        window?.sessionStorage && window.sessionStorage.setItem(
          ELEVAR_CUSTOMER_SESSION_NAME,
          JSON.stringify(userProperties)
        )
        return userProperties
      });
    }
    else {
      setUserProperties({
        user_consent: '',
        visitor_type: customer ? 'logged_in' : 'guest'
      })
      window?.sessionStorage && window.sessionStorage.setItem(
        ELEVAR_CUSTOMER_SESSION_NAME,
        JSON.stringify({
          user_consent: '',
          visitor_type: customer ? 'logged_in' : 'guest'
        })
      )
    }
  }, [customerData]);

  return {
    userProperties,
    isLoadingCustomer
  };
}

export default useUserProperties;
