declare global {
  interface Window {
    atob: any
  }
}

export const getIdFromGraphQlId = (ID: any | string, replaceString: string) => {
  if (ID?.startsWith(replaceString)) return ID.replace(replaceString, '')
  let idString = ''
  try {
    idString =
      typeof window !== 'undefined' && window?.atob ? atob(ID.toString()) : Buffer.from(ID, 'base64').toString()
  } catch (e) {
    console.error(`Error in getIdFromGraphQlId: ${ID}`, e)
  }
  return idString.replace(replaceString, '')
}

export enum BuildTypes {
  FULL = 'FULL',
  PART = 'PART',
  NONE = 'NONE',
}
