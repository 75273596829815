import { Product as ElevarProduct } from '@lib/types/Elevar'
import { ThemeProvider } from 'next-themes'
import React, { FC, useCallback, useMemo } from 'react'
export interface State {
  displaySidebar: boolean
  displayDropdown: boolean
  displayModal: boolean
  displaySearch: boolean
  sidebarView: string
  modalView: string
  userAvatar: string
  stampedInIt: boolean
  tags: TAG[]
  promoTileParentVariantId: string | null
  selectedGiftCardHandle: string | null
  selectedDigitalCardPrice: number | null
  elevarProductViewProductsList: ElevarProduct[] | null
}

const initialState = {
  displaySidebar: false,
  displayDropdown: false,
  displayModal: false,
  displaySearch: false,
  displayMobileNav: false,
  displayMegaMenu: false,
  displayFilters: false,
  displayHelpMenu: false,
  formActive: false,
  modalView: 'LOGIN_VIEW',
  sidebarView: 'CART_VIEW',
  userAvatar: '',
  stampedInIt: false,
  activeChat: false,
  arPopupView: false,
  tags: [],
  promoTileParentVariantId: null,
  selectedGiftCardHandle: '',
  selectedDigitalCardPrice: 0,
  elevarProductViewProductsList: null,
}

type Action =
  | {
      type: 'OPEN_SIDEBAR'
    }
  | {
      type: 'CLOSE_SIDEBAR'
    }
  | {
      type: 'OPEN_DROPDOWN'
    }
  | {
      type: 'CLOSE_DROPDOWN'
    }
  | {
      type: 'OPEN_SEARCH'
    }
  | {
      type: 'CLOSE_SEARCH'
    }
  | {
      type: 'OPEN_MOBILE_NAV'
    }
  | {
      type: 'CLOSE_MOBILE_NAV'
    }
  | {
      type: 'OPEN_MEGA_MENU'
    }
  | {
      type: 'CLOSE_MEGA_MENU'
    }
  | {
      type: 'OPEN_FILTERS'
    }
  | {
      type: 'CLOSE_FILTERS'
    }
  | {
      type: 'OPEN_HELP_MENU'
    }
  | {
      type: 'CLOSE_HELP_MENU'
    }
  | {
      type: 'FORM_FOCUSED'
    }
  | {
      type: 'FORM_UNFOCUSED'
    }
  | {
      type: 'OPEN_MODAL'
    }
  | {
      type: 'CLOSE_MODAL'
    }
  | {
      type: 'OPEN_CHAT'
    }
  | {
      type: 'SET_MODAL_VIEW'
      view: MODAL_VIEWS
    }
  | {
      type: 'SET_AR_POPUP_OPEN'
      view: boolean
    }
  | {
      type: 'SET_SIDEBAR_VIEW'
      view: SIDEBAR_VIEWS
    }
  | {
      type: 'SET_USER_AVATAR'
      value: string
    }
  | {
      type: 'STAMPED_INIT'
    }
  | {
      type: 'SET_TAG_LIBRARY_TAGS'
      value: TAG[]
    }
  | {
      type: 'SET_PROMO_TILE_PARENT_VARIANT_ID'
      value: string
    }
  | {
      type: 'SET_SELECTED_GIFT_CARD_HANDLE'
      value: string
    }
  | {
      type: 'SET_SELECTED_DIGITAL_CARD_PRICE'
      value: number
    }
  | {
      type: 'SET_ELEVAR_PRODUCT_VIEW_PRODUCTS_LIST'
      value: any
    }

type TAG = {
  uid: string
  background_colour: string
  text_colour?: string
  label_text: string
}

type MODAL_VIEWS = 'COUNTRY_SELECTOR_VIEW' | 'STORE_SUGGESTION'

type SIDEBAR_VIEWS = 'CART_VIEW' | 'CHECKOUT_VIEW' | 'PAYMENT_METHOD_VIEW | COUNTRY_SELECTOR_VIEW'

export const UIContext = React.createContext<State | any>(initialState)

UIContext.displayName = 'UIContext'

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case 'OPEN_SIDEBAR': {
      return {
        ...state,
        displaySidebar: true,
      }
    }
    case 'CLOSE_SIDEBAR': {
      return {
        ...state,
        displaySidebar: false,
      }
    }
    case 'OPEN_DROPDOWN': {
      return {
        ...state,
        displayDropdown: true,
      }
    }
    case 'CLOSE_DROPDOWN': {
      return {
        ...state,
        displayDropdown: false,
      }
    }
    case 'OPEN_SEARCH': {
      return {
        ...state,
        displaySearch: true,
      }
    }
    case 'CLOSE_SEARCH': {
      return {
        ...state,
        displaySearch: false,
      }
    }
    case 'OPEN_MOBILE_NAV': {
      return {
        ...state,
        displayMobileNav: true,
      }
    }
    case 'CLOSE_MOBILE_NAV': {
      return {
        ...state,
        displayMobileNav: false,
      }
    }
    case 'OPEN_MEGA_MENU': {
      return {
        ...state,
        displayMegaMenu: true,
      }
    }
    case 'CLOSE_MEGA_MENU': {
      return {
        ...state,
        displayMegaMenu: false,
      }
    }
    case 'OPEN_FILTERS': {
      return {
        ...state,
        displayFilters: true,
      }
    }
    case 'CLOSE_FILTERS': {
      return {
        ...state,
        displayFilters: false,
      }
    }
    case 'OPEN_HELP_MENU': {
      return {
        ...state,
        displayHelpMenu: true,
      }
    }
    case 'CLOSE_HELP_MENU': {
      return {
        ...state,
        displayHelpMenu: false,
      }
    }
    case 'FORM_FOCUSED': {
      return {
        ...state,
        formActive: true,
      }
    }
    case 'FORM_UNFOCUSED': {
      return {
        ...state,
        formActive: false,
      }
    }
    case 'OPEN_MODAL': {
      return {
        ...state,
        displayModal: true,
        displaySidebar: false,
      }
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        displayModal: false,
      }
    }
    case 'OPEN_CHAT': {
      return {
        ...state,
        activeChat: true,
      }
    }
    case 'SET_MODAL_VIEW': {
      return {
        ...state,
        modalView: action.view,
      }
    }
    case 'SET_AR_POPUP_OPEN': {
      return {
        ...state,
        arPopupView: action.view,
      }
    }
    case 'SET_SIDEBAR_VIEW': {
      return {
        ...state,
        sidebarView: action.view,
      }
    }
    case 'SET_USER_AVATAR': {
      return {
        ...state,
        userAvatar: action.value,
      }
    }
    case 'STAMPED_INIT': {
      return {
        ...state,
        stampedInIt: true,
      }
    }
    case 'SET_TAG_LIBRARY_TAGS': {
      return {
        ...state,
        tags: action.value,
      }
    }
    case 'SET_PROMO_TILE_PARENT_VARIANT_ID': {
      return {
        ...state,
        promoTileParentVariantId: action.value,
      }
    }
    case 'SET_SELECTED_GIFT_CARD_HANDLE': {
      return {
        ...state,
        selectedGiftCardHandle: action.value,
      }
    }
    case 'SET_SELECTED_DIGITAL_CARD_PRICE': {
      return {
        ...state,
        selectedDigitalCardPrice: action.value,
      }
    }
    case 'SET_ELEVAR_PRODUCT_VIEW_PRODUCTS_LIST': {
      return {
        ...state,
        elevarProductViewProductsList: action.value,
      }
    }
  }
}

export const UIProvider: FC<any> = (props) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState)

  const openSidebar = useCallback(() => dispatch({ type: 'OPEN_SIDEBAR' }), [dispatch])
  const closeSidebar = useCallback(() => dispatch({ type: 'CLOSE_SIDEBAR' }), [dispatch])
  const toggleSidebar = useCallback(
    () => (state.displaySidebar ? dispatch({ type: 'CLOSE_SIDEBAR' }) : dispatch({ type: 'OPEN_SIDEBAR' })),
    [dispatch, state.displaySidebar]
  )
  const closeSidebarIfPresent = useCallback(
    () => state.displaySidebar && dispatch({ type: 'CLOSE_SIDEBAR' }),
    [dispatch, state.displaySidebar]
  )

  const openDropdown = useCallback(() => dispatch({ type: 'OPEN_DROPDOWN' }), [dispatch])
  const closeDropdown = useCallback(() => dispatch({ type: 'CLOSE_DROPDOWN' }), [dispatch])

  const openSearch = useCallback(() => dispatch({ type: 'OPEN_SEARCH' }), [dispatch])
  const closeSearch = useCallback(() => dispatch({ type: 'CLOSE_SEARCH' }), [dispatch])

  const openMobileNav = useCallback(() => dispatch({ type: 'OPEN_MOBILE_NAV' }), [dispatch])
  const closeMobileNav = useCallback(() => dispatch({ type: 'CLOSE_MOBILE_NAV' }), [dispatch])

  const openMegaMenu = useCallback(() => dispatch({ type: 'OPEN_MEGA_MENU' }), [dispatch])
  const closeMegaMenu = useCallback(() => dispatch({ type: 'CLOSE_MEGA_MENU' }), [dispatch])

  const openFilters = useCallback(() => dispatch({ type: 'OPEN_FILTERS' }), [dispatch])
  const closeFilters = useCallback(() => dispatch({ type: 'CLOSE_FILTERS' }), [dispatch])

  const openModal = useCallback(() => dispatch({ type: 'OPEN_MODAL' }), [dispatch])
  const closeModal = useCallback(() => dispatch({ type: 'CLOSE_MODAL' }), [dispatch])

  const setActiveChat = useCallback(() => dispatch({ type: 'OPEN_CHAT' }), [dispatch])

  const openHelpMenu = useCallback(() => dispatch({ type: 'OPEN_HELP_MENU' }), [dispatch])
  const closeHelpMenu = useCallback(() => dispatch({ type: 'CLOSE_HELP_MENU' }), [dispatch])

  const formFocused = useCallback(() => dispatch({ type: 'FORM_FOCUSED' }), [dispatch])
  const formUnfocused = useCallback(() => dispatch({ type: 'FORM_UNFOCUSED' }), [dispatch])

  const setUserAvatar = useCallback((value: string) => dispatch({ type: 'SET_USER_AVATAR', value }), [dispatch])

  const setModalView = useCallback((view: MODAL_VIEWS) => dispatch({ type: 'SET_MODAL_VIEW', view }), [dispatch])

  const setArPopupView = useCallback((view: boolean) => dispatch({ type: 'SET_AR_POPUP_OPEN', view }), [dispatch])

  const setSidebarView = useCallback((view: SIDEBAR_VIEWS) => dispatch({ type: 'SET_SIDEBAR_VIEW', view }), [dispatch])

  const stampedInitiated = useCallback(() => dispatch({ type: 'STAMPED_INIT' }), [dispatch])

  const setTagLibraryTags = useCallback((value: TAG[]) => dispatch({ type: 'SET_TAG_LIBRARY_TAGS', value }), [dispatch])

  const setPromoTileParentVariantId = useCallback(
    (value: string) => dispatch({ type: 'SET_PROMO_TILE_PARENT_VARIANT_ID', value }),
    [dispatch]
  )

  const setSelectedGiftCardHandle = useCallback(
    (value: string) => dispatch({ type: 'SET_SELECTED_GIFT_CARD_HANDLE', value }),
    [dispatch]
  )

  const setSelectedDigitalCardPrice = useCallback(
    (value: number) => dispatch({ type: 'SET_SELECTED_DIGITAL_CARD_PRICE', value }),
    [dispatch]
  )

  const setElevarProductViewProductsList = useCallback(
    (value: any) => {
      value && dispatch({ type: 'SET_ELEVAR_PRODUCT_VIEW_PRODUCTS_LIST', value })
    },
    [dispatch]
  )
  const value = useMemo(
    () => ({
      ...state,
      openSidebar,
      closeSidebar,
      toggleSidebar,
      closeSidebarIfPresent,
      openDropdown,
      closeDropdown,
      openSearch,
      closeSearch,
      openMobileNav,
      closeMobileNav,
      openMegaMenu,
      closeMegaMenu,
      openFilters,
      closeFilters,
      openHelpMenu,
      closeHelpMenu,
      openModal,
      closeModal,
      setActiveChat,
      formFocused,
      formUnfocused,
      setModalView,
      setSidebarView,
      setUserAvatar,
      stampedInitiated,
      setArPopupView,
      setTagLibraryTags,
      setPromoTileParentVariantId,
      setSelectedGiftCardHandle,
      setSelectedDigitalCardPrice,
      setElevarProductViewProductsList,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  )

  return <UIContext.Provider value={value} {...props} />
}

export const useUI = () => {
  const context = React.useContext(UIContext)
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }
  return context
}

interface Props {
  children: React.ReactNode
}
export const ManagedUIContext: FC<Props> = ({ children }) => (
  <UIProvider>
    <ThemeProvider>{children}</ThemeProvider>
  </UIProvider>
)
