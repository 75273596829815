import commerceConfig from '@commerce/config'
import processLocale from '@lib/locale'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

const Elevar = () => {
  const { locale } = useRouter()
  const {
    config: { context },
  } = commerceConfig
  const currentLocale = processLocale(locale)
  const currentContext = context?.[currentLocale]
  const elevarId = currentContext?.elevarId
  useEffect(() => {
    const elevarScript = document.getElementsByClassName(`elevar`)
    for (const script of elevarScript) {
      script.remove()
    }
    window.ElevarDataLayer = undefined
    window.ElevarInvalidateContext = undefined
    window.ElevarDebugMode = undefined
    window.ElevarTransformFn = undefined
    window.ElevarUserIdFn = undefined
    if (elevarId) {
      const newScript = document.createElement('script')
      newScript.id = `elevar-${locale}`
      newScript.className = 'elevar'
      newScript.type = 'module'
      newScript.innerHTML = `try {
            const response = await fetch("https://shopify-gtm-suite.getelevar.com/configs/${elevarId}/config.json");
            const config = await response.json();
            const scriptUrl = config.script_src_custom_pages;
        
            if (scriptUrl) {
              const { handler } = await import(scriptUrl);
              await handler(config);
            }
          } catch (error) {
            console.error("Elevar Error:", error);
          }
          `
      document.body.appendChild(newScript)
    }
  }, [elevarId, locale])

  return null
}

export default Elevar
