const config = {
  defaultLocale: 'en',
  fakeLocale: 'default',
  prismicDefaultLocale: 'en-au',
  shopifyCookieExpiry: 30,
  defaultAlt: 'Sheet Society',
  context: {
    en: {
      enabled:
        process.env.NEXT_PUBLIC_LOCALE_ENABLED_ROW === 'true' ? true : false,
      shopifyDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_ROW,
      shopifyAPIToken:
        process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN_ROW,
      shopifyMultiPassSecret: process.env.NEXT_SHOPIFY_MULTIPASS_SECRET_AU,
      // shopifyAdminApiURL: process.env.SHOPIFY_ADMIN_API_URL_ROW,
      // shopifyAdminApiToken: process.env.SHOPIFY_ADMIN_API_TOKEN_ROW,
      defaultCurrencyCountryCode: 'AU',
      defaultCurrencyCode: 'AUD',
      algoliaProductIndex: 'shopify_en_au_products',
      checkoutIdCookieName: 'tss_row_checkoutId',
      checkoutURLCookieName: 'tss_row_checkoutUrl',
      customerTokenCookieName: 'tss_row_customerToken',
      klaviyoPublicApiKey: process.env.KLAVIYO_API_KEY_ROW,
      klaviyoPrivateApiKey: process.env.KLAVIYO_PRIVATE_API_KEY_ROW,
      language: 'en',
      stampedApiKey: process.env.NEXT_PUBLIC_STAMPED_API_KEY, // TO-DO: check if specific API key for locale is needed
      stampedStoreUrl: 'the-sheet-society.myshopify.com',
      loopReturnsApiKey: process.env.LOOP_RETURNS_API_KEY_ROW,
      loopReturnsUrl: 'https://returns-au.sheetsociety.com',
      paymentMethods: ['AFTERPAY'],
      elevarId: process.env.NEXT_PUBLIC_ELEVAR_ID_EN,
    },
    'en-au': {
      enabled:
        process.env.NEXT_PUBLIC_LOCALE_ENABLED_AU === 'true' ? true : false,
      shopifyDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_AU,
      shopifyAPIToken:
        process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN_AU,
      shopifyMultiPassSecret: process.env.NEXT_SHOPIFY_MULTIPASS_SECRET_AU,
      // shopifyAdminApiURL: process.env.SHOPIFY_ADMIN_API_URL_AU,
      // shopifyAdminApiToken: process.env.SHOPIFY_ADMIN_API_TOKEN_AU,
      defaultCurrencyCountryCode: 'AU',
      defaultCurrencyCode: 'AUD',
      algoliaProductIndex: process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX_AU,
      checkoutIdCookieName: 'tss_au_checkoutId',
      checkoutURLCookieName: 'tss_au_checkoutUrl',
      customerTokenCookieName: 'tss_au_customerToken',
      klaviyoPublicApiKey: process.env.KLAVIYO_API_KEY_AU,
      klaviyoPrivateApiKey: process.env.KLAVIYO_PRIVATE_API_KEY_AU,
      language: 'en-au',
      stampedApiKey: process.env.NEXT_PUBLIC_STAMPED_API_KEY, // TO-DO: check if specific API key for locale is needed
      stampedStoreUrl: 'the-sheet-society.myshopify.com',
      loopReturnsApiKey: process.env.LOOP_RETURNS_API_KEY_AU,
      loopReturnsUrl: 'https://returns-au.sheetsociety.com',
      paymentMethods: ['AFTERPAY'],
      elevarId: process.env.NEXT_PUBLIC_ELEVAR_ID_AU,
    },
    'en-nz': {
      enabled:
        process.env.NEXT_PUBLIC_LOCALE_ENABLED_NZ === 'true' ? true : false,
      shopifyDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_NZ,
      shopifyAPIToken:
        process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN_NZ,
      shopifyMultiPassSecret: process.env.NEXT_SHOPIFY_MULTIPASS_SECRET_NZ,
      // shopifyAdminApiURL: process.env.SHOPIFY_ADMIN_API_URL_US,
      // shopifyAdminApiToken: process.env.SHOPIFY_ADMIN_API_TOKEN_US,
      defaultCurrencyCountryCode: 'NZ',
      defaultCurrencyCode: 'NZD',
      algoliaProductIndex: process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX_NZ,
      checkoutIdCookieName: 'tss_nz_checkoutId',
      checkoutURLCookieName: 'tss_nz_checkoutUrl',
      customerTokenCookieName: 'tss_nz_customerToken',
      klaviyoPublicApiKey: process.env.KLAVIYO_API_KEY_NZ,
      klaviyoPrivateApiKey: process.env.KLAVIYO_PRIVATE_API_KEY_NZ,
      language: 'en-nz',
      stampedApiKey: process.env.NEXT_PUBLIC_STAMPED_API_KEY, // All stores has same key except GB
      stampedStoreUrl: 'sheetsociety-nz.myshopify.com',
      loopReturnsApiKey: process.env.LOOP_RETURNS_API_KEY_NZ,
      loopReturnsUrl: 'https://returns-nz.sheetsociety.com',
      paymentMethods: ['AFTERPAY'],
      elevarId: process.env.NEXT_PUBLIC_ELEVAR_ID_NZ,
    },
    'en-us': {
      enabled:
        process.env.NEXT_PUBLIC_LOCALE_ENABLED_US === 'true' ? true : false,
      shopifyDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_US,
      shopifyAPIToken:
        process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN_US,
      shopifyMultiPassSecret: process.env.NEXT_SHOPIFY_MULTIPASS_SECRET_US,
      // shopifyAdminApiURL: process.env.SHOPIFY_ADMIN_API_URL_US,
      // shopifyAdminApiToken: process.env.SHOPIFY_ADMIN_API_TOKEN_US,
      defaultCurrencyCountryCode: 'US',
      defaultCurrencyCode: 'USD',
      algoliaProductIndex: process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX_US,
      checkoutIdCookieName: 'tss_us_checkoutId',
      checkoutURLCookieName: 'tss_us_checkoutUrl',
      customerTokenCookieName: 'tss_us_customerToken',
      klaviyoPublicApiKey: process.env.KLAVIYO_API_KEY_US,
      klaviyoPrivateApiKey: process.env.KLAVIYO_PRIVATE_API_KEY_US,
      language: 'en-us',
      stampedApiKey: process.env.NEXT_PUBLIC_STAMPED_API_KEY, // All the store has same key except GB
      stampedStoreUrl: 'thesheetsociety.myshopify.com',
      loopReturnsApiKey: process.env.LOOP_RETURNS_API_KEY_US,
      loopReturnsUrl: 'https://returns-us.sheetsociety.com',
      paymentMethods: ['AFTERPAY'],
      elevarId: process.env.NEXT_PUBLIC_ELEVAR_ID_US,
    },
    'en-ca': {
      enabled:
        process.env.NEXT_PUBLIC_LOCALE_ENABLED_CA === 'true' ? true : false,
      shopifyDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_CA,
      shopifyAPIToken:
        process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN_CA,
      shopifyMultiPassSecret: process.env.NEXT_SHOPIFY_MULTIPASS_SECRET_CA,
      // shopifyAdminApiURL: process.env.SHOPIFY_ADMIN_API_URL_US,
      // shopifyAdminApiToken: process.env.SHOPIFY_ADMIN_API_TOKEN_US,
      defaultCurrencyCountryCode: 'CA',
      defaultCurrencyCode: 'CAD',
      algoliaProductIndex: process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX_CA,
      checkoutIdCookieName: 'tss_ca_checkoutId',
      checkoutURLCookieName: 'tss_ca_checkoutUrl',
      customerTokenCookieName: 'tss_ca_customerToken',
      klaviyoPublicApiKey: process.env.KLAVIYO_API_KEY_CA,
      klaviyoPrivateApiKey: process.env.KLAVIYO_PRIVATE_API_KEY_CA,
      language: 'en-ca',
      stampedApiKey: process.env.NEXT_PUBLIC_STAMPED_API_KEY, // TO-DO: All the store has same key except GB
      stampedStoreUrl: 'sheetsociety-ca.myshopify.com',
      loopReturnsApiKey: process.env.LOOP_RETURNS_API_KEY_CA,
      loopReturnsUrl: 'https://returns-ca.sheetsociety.com',
      paymentMethods: ['AFTERPAY'],
      elevarId: process.env.NEXT_PUBLIC_ELEVAR_ID_CA,
    },
    'en-gb': {
      enabled:
        process.env.NEXT_PUBLIC_LOCALE_ENABLED_GB === 'true' ? true : false,
      shopifyDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_GB,
      shopifyAPIToken:
        process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN_GB,
      shopifyMultiPassSecret: process.env.NEXT_SHOPIFY_MULTIPASS_SECRET_GB,
      // shopifyAdminApiURL: process.env.SHOPIFY_ADMIN_API_URL_US,
      // shopifyAdminApiToken: process.env.SHOPIFY_ADMIN_API_TOKEN_US,
      defaultCurrencyCountryCode: 'GB',
      defaultCurrencyCode: 'GBP',
      algoliaProductIndex: process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX_GB,
      checkoutIdCookieName: 'tss_gb_checkoutId',
      checkoutURLCookieName: 'tss_gb_checkoutUrl',
      customerTokenCookieName: 'tss_gb_customerToken',
      klaviyoPublicApiKey: process.env.KLAVIYO_API_KEY_GB,
      klaviyoPrivateApiKey: process.env.KLAVIYO_PRIVATE_API_KEY_GB,
      language: 'en-gb',
      stampedApiKey: process.env.NEXT_PUBLIC_STAMPED_API_KEY_GB,
      stampedStoreUrl: 'thesheetsociety-uk.myshopify.com',
      loopReturnsApiKey: process.env.LOOP_RETURNS_API_KEY_GB,
      loopReturnsUrl: 'https://returns-gb.sheetsociety.com',
      paymentMethods: ['CLEARPAY', 'KLARNA'],
      elevarId: process.env.NEXT_PUBLIC_ELEVAR_ID_GB,
    },
  },
}

module.exports = { config }
