import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, from, makeVar } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import processLocale from '@lib/locale'
import * as Sentry from '@sentry/nextjs'
import commerceConfig from './config'

const {
  config: { context },
} = commerceConfig
const initToken: string | null = ''
export const customerAccessTokenVar = makeVar(initToken)

const contextLink = new ApolloLink((operation, forward) => {
  const { locale } = operation.getContext()
  const currentLocale = processLocale(locale)
  const currentContext = context?.[currentLocale]

  if (!currentContext || !currentContext?.shopifyDomain || !currentContext?.shopifyAPIToken) {
    throw new Error(`The shopify env settings are missing for processLocale: ${currentLocale} and locale: ${locale}`)
  }

  // console.log('locale inside client', currentLocale);
  // console.log("domain",currentContext.shopifyDomain);
  // console.log("toekn",currentContext.shopifyAPIToken);

  operation.setContext(() => ({
    uri: `https://${currentContext.shopifyDomain}/api/2023-10/graphql.json`,
    headers: { 'X-Shopify-Storefront-Access-Token': currentContext.shopifyAPIToken, 'Accept-Language': currentLocale },
  }))

  return forward(operation)
})

const client = new ApolloClient({
  link: from([
    contextLink,
    onError(({ graphQLErrors, networkError }) => {
      if (networkError) {
        console.error(`[Network error]: ${networkError}`)
        Sentry.captureException(networkError)
      }

      if (graphQLErrors && Array.isArray(graphQLErrors)) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
          Sentry.captureException({
            message,
            locations,
            path,
          })
        })
      } else {
        Sentry.captureException(graphQLErrors)
      }
    }),
    new HttpLink(),
  ]),
  cache: new InMemoryCache(),
})

export default client
