import { FC } from 'react'
import Script from 'next/script'
import NextHead from 'next/head'

const ImpactTag: FC = () => {
  return (
    <NextHead>
      <script
        id="impact-universal-tracking"
        dangerouslySetInnerHTML={{
          __html: `(function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;g.parentNode.insertBefore(f,g);})('https://utt.impactcdn.com/A3435965-1014-4d50-858d-ce007736ecba1.js','script','ire',document,window); `,
        }}
      ></script>
    </NextHead>
  )
}
export default ImpactTag
