import { ElevarEvents } from '@lib/events/elevarEvents'
import useUserProperties from '@lib/hooks/useUserProperties'
import crypto from 'crypto'
import { useCallback, useEffect, useState } from 'react'
export const CUSTOMER_SESSION_NAME = 'SSUL'

const useCustomer = () => {
  const [loggedInCustomer, setLoggedInCustomer] = useState(null)
  const { userProperties } = useUserProperties()
  useEffect(() => {
    if (window?.sessionStorage) {
      const customer = window.sessionStorage.getItem(CUSTOMER_SESSION_NAME)
      if (customer) {
        setLoggedInCustomer(JSON.parse(customer))
      }
    }
  }, [])

  const loginCustomer = useCallback(
    (customer: any, elevarLogin?: boolean) => {
      setLoggedInCustomer(customer)
      elevarLogin && customer.email == userProperties.customer_email && ElevarEvents('dl_login', userProperties)
      const shasum = crypto.createHash('sha1')
      let email = ''
      if (customer.email) {
        shasum.update(customer.email)
        email = shasum.digest('hex')
      }
      if (window?.sessionStorage) {
        //Save customer id and sha1 email to sessionStorage
        window.sessionStorage.setItem(
          CUSTOMER_SESSION_NAME,
          JSON.stringify({
            i: customer.id,
            e: email,
          })
        )
      }
    },
    [userProperties]
  )

  const logoutCustomer = useCallback(() => {
    setLoggedInCustomer(null)
    if (window?.sessionStorage) {
      window.sessionStorage.removeItem(CUSTOMER_SESSION_NAME)
    }
  }, [])

  return {
    loggedInCustomer,
    loginCustomer,
    logoutCustomer,
  }
}

export default useCustomer
