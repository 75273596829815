import { ApolloProvider } from '@apollo/client'
import '@assets/chrome-bug.css'
import '@assets/main.css'
import client from '@commerce/client'
import { Head } from '@components/common'
import { ManagedUIContext } from '@components/ui/context'

import { pushContextToDataLayer, pushUserDataToDataLayer } from '@lib/events/elevarEvents'
import { impactTagEvent } from '@lib/events/impactTagEvent'
import { pageView } from '@lib/events/pageView'
import 'keen-slider/keen-slider.min.css'
import type { AppProps } from 'next/app'
import Router, { useRouter } from 'next/router'
import NProgress from 'nprogress'
import { FC, ReactNode, useEffect } from 'react'
import './builder.css'

interface NoopProps {
  children: ReactNode
}

const Noop: FC<NoopProps> = ({ children }) => <>{children}</>

NProgress.configure({ showSpinner: false })
Router.events.on('routeChangeStart', () => {
  NProgress.start()
})

Router.events.on('routeChangeComplete', () => {
  NProgress.done()
})

Router.events.on('routeChangeError', () => {
  NProgress.done()
})

export default function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const Layout = (Component as any).Layout || Noop

  useEffect(() => {
    document.body.classList?.remove('loading')
  }, [])

  useEffect(() => {
    router.events.on('routeChangeComplete', pageView)
    router.events.on('routeChangeComplete', impactTagEvent)
    router.events.on('routeChangeStart', pushContextToDataLayer) //Elevar
    router.events.on('routeChangeStart', pushUserDataToDataLayer) //Elevar
    return () => {
      router.events.off('routeChangeComplete', pageView)
      router.events.off('routeChangeComplete', impactTagEvent)
      router.events.off('routeChangeStart', pushContextToDataLayer) //Elevar
      router.events.off('routeChangeStart', pushUserDataToDataLayer) //Elevar
    }
  }, [router.events])

  return (
    <ApolloProvider client={client}>
      <ManagedUIContext>
        <Head globalSettings={pageProps.globalSettings} />
        <Layout pageProps={pageProps}>
          <Component {...pageProps} />
        </Layout>
      </ManagedUIContext>
    </ApolloProvider>
  )
}
