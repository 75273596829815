import { Product } from '@commerce/types'
import { BedBuilderProduct, BedBuilderProductVariant } from '@components/builder/declarations/types'
import { getIdFromGraphQlId } from '@lib/general'
import processLocale from '@lib/locale'
import { Crumb } from '@lib/types/Breadcrumbs'

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID
export const EVENT_DEBUG = process.env.NEXT_PUBLIC_EVENT_DEBUG
export default GTM_ID

export const infoLog = (message: string) => {
  console.log('%c%s', 'color: white; background: green; padding: 2px 10px;', message)
}

export const eventLog = (message: string, event: any) => {
  console.log(
    '%c%s',
    'color: white; background: teal; padding: 2px 10px;',
    message,
    event ? event.detail : 'No event details available.'
  )
}
export const getProductTieredBreadCrumb = (crumbs: Crumb[], seperator: string = ' / ') => {
  return crumbs
    ?.filter((c) => c?.link !== '' && c?.label?.toLowerCase().trim() !== 'home')
    .map((c) => c?.label || c?.title)
    .join(seperator)
}

export const getNumberPartFromId = (id: string) => {
  return id ? id.split('/')[id.split('/').length - 1] : ''
}

export const getDataLayerProductIdForAlgolia = (p: Product.Hit, locale?: string) => {
  const l = processLocale(locale)
  return `shopify_${l?.toUpperCase().replace('EN-', '')}_${p.id}_${p.objectID}`
}

export const getDataLayerProductIdForShopify = (p: Product.Product, v?: Product.ProductVariant, locale?: string) => {
  const l = processLocale(locale)
  return v
    ? `shopify_${l?.toUpperCase().replace('EN-', '')}_${getIdFromGraphQlId(
      p?.id,
      'gid://shopify/Product/'
    )}_${getIdFromGraphQlId(v.id, 'gid://shopify/ProductVariant/')}`
    : `shopify_${locale?.toUpperCase().replace('EN-', '')}_${getIdFromGraphQlId(p?.id, 'gid://shopify/Product/')}`
}

export const getDataLayerProductIdBedBuilderForShopify = (
  p: BedBuilderProduct,
  v?: BedBuilderProductVariant,
  locale?: string
) => {
  const l = processLocale(locale)
  return v
    ? `shopify_${l?.toUpperCase().replace('EN-', '')}_${getIdFromGraphQlId(
      p?.id,
      'gid://shopify/Product/'
    )}_${getIdFromGraphQlId(
      v.id,
      'gid://shopify/ProductVariant/'
    )}`
    : `shopify_${locale?.toUpperCase().replace('EN-', '')}_${getIdFromGraphQlId(
      p?.id,
      'gid://shopify/Product/'
    )}`
}

export const getDataLayerProductIdById = (id: string | number, locale?: string) => {
  const l = processLocale(locale)
  return `shopify_${l?.toUpperCase().replace('EN-', '')}_${id}`
}

export const getDataLayerProductIdByIds = (productId: string, variantId?: string, locale?: string) => {
  const l = processLocale(locale)
  return variantId
    ? `shopify_${l?.toUpperCase().replace('EN-', '')}_${getIdFromGraphQlId(
      productId,
      'gid://shopify/Product/'
    )}_${getIdFromGraphQlId(variantId, 'gid://shopify/ProductVariant/')}`
    : `shopify_${locale?.toUpperCase().replace('EN-', '')}_${getIdFromGraphQlId(productId, 'gid://shopify/Product/')}`
}
